import * as React from "react"
import PropTypes from "prop-types"

class TarjetaServicio extends React.Component {
 constructor(props) {
    super(props);
    this.state = {
      visible: '',
    };
  }
  render(){
	return (<>
		<div id={this.props.idServicio} className={"service"} 
			onMouseOver={() => this.setState({visible: 'visible'}) }
			onMouseLeave={() => this.setState({visible: ''}) }		
			  data-sal="slide-up"
			  data-sal-delay="0"
			  data-sal-easing="ease"
			  data-sal-duration="400"
		>
			<div className={"serviceOver "+this.state.visible}>{this.props.serviceName}</div>
			<div className="serviceHeader">
				<span className="serviceName">{this.props.serviceName}</span>
				<h2 className="serviceDesc">{this.props.serviceDesc}</h2>
				<span className="serviceArrowRight">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
</svg>
				</span>
			</div>
			<div className="serviceBody">
					<ul>
					{this.props.servicios.map( s => <li><h2>{s}</h2></li> )}
					</ul>
			</div>
		</div>
	
	</>)
	}
}

TarjetaServicio.propTypes = {
  idServicio: PropTypes.string,
}

TarjetaServicio.defaultProps = {
  idServicio: ``
}

export default TarjetaServicio
